import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PostItem from "../components/postitem";


class BlogIndex extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges.filter((post) => post.node.fields.slug.startsWith("/blog"));

        let formattedPosts = posts.map((post) => {
            return {
                route: post.node.frontmatter.path,
                title: post.node.frontmatter.title,
                date: post.node.frontmatter.date,
                category: post.node.frontmatter.category.split(",").map(cat => cat.trim()),
                html: post.node.html
            };
        });

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Blog" />

                <div className="app-post">
                    {
                        formattedPosts.map((post) => <PostItem key={post.title} post={post}></PostItem>)
                    }
                </div>

            </Layout>
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            path
            title
            date(formatString: "MMMM DD, YYYY")
            category
            keywords
          }
        }
      }
    }
  }
`
